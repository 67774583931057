import React, { useEffect } from 'react';
import { WixRicosViewerNextLazy } from '../rich-content-viewer/wix-ricos-viewer-next-lazy';

const RichContentViewerLoader = () => {
  useEffect(() => {
    WixRicosViewerNextLazy.preload();
  }, []);

  return <React.Fragment />;
};

export default RichContentViewerLoader;
